.App-center {
  text-align: center;
}

.App-pdf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
 } 

.App-header {
  background-color: #8C0005; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-format {
  padding-left: 10%;
  padding-right: 10%;
}

.leaflet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
	height: 80vh;
	width: 100%;
}

.App-tab{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Header-h1 {
  font-weight: bold;
  font-size: 4em;
  margin-bottom: unset;
}

.App-color {
  color: #8C0005;
}

.Store-images {
  height: 50%;
  width: 50%;
}